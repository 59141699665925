import 'bootstrap'
import 'jquery'

window.$ = jQuery
window.jQuery = jQuery
window.jquery = jQuery
function requireAll(r) { r.keys().forEach(r); }



import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'

import 'popper.js'
import 'bootstrap'

import '@fortawesome/fontawesome-free/scss/fontawesome'
import '@fortawesome/fontawesome-free/scss/solid'
// import '@fortawesome/fontawesome-free/scss/regular'
import '@fortawesome/fontawesome-free/scss/brands'

import '../assets/stylesheets/application.scss'

import '../assets/svg/logos/logo.svg'
import '../assets/svg/logos/nosco_logo_symbol.svg'
import '../assets/svg/logos/nosco_logo.svg'
import '../assets/svg/logos/nosco_logo_full_negative.svg'
import '../assets/svg/logos/leanbit_logo_symbol_rounded.svg'
import '../assets/svg/logos/leanbit_logo_with_symbol.svg'
import '../assets/svg/logos/amazon-connect.svg'
import '../assets/svg/logos/telegram.svg'
import '../assets/svg/logos/whatsapp.svg'
import '../assets/svg/logos/email-solid.svg'
import '../assets/svg/logos/chat-dots.svg'
import '../assets/svg/logos/leanbit_logo.svg'
import '../assets/svg/logos/leanbit_bianco.svg'
import '../assets/svg/logos/unox_logo.svg'
import '../assets/svg/logos/unox_logo_2.svg'
import '../assets/svg/illustrations/hiker-man.svg'
import '../assets/svg/illustrations/undraw_circuit_board_4c4d.svg'
import '../assets/svg/illustrations/undraw_task_31wc.svg'
import '../assets/svg/illustrations/undraw_online_collaboration.svg'
import '../assets/svg/components/dots-2.svg'
import '../assets/svg/components/dots-5.svg'
import '../assets/svg/components/hexagon_outline.svg'
import '../assets/svg/components/hexagon_outline_cropped.svg'
import '../assets/svg/screens/omnichannel_inbox_hexagon.svg'
import '../assets/svg/screens/omnichannel_inbox_hexagon_cropped.svg'

import '../assets/images/nosco_panel.jpg'
import '../assets/images/nosco_cloud_and_io.png'
import '../assets/images/nosco_cloud.png'
import '../assets/images/nosco_io.png'
import '../assets/images/prescrittivo.png'
import '../assets/images/reattivo.png'
import '../assets/images/hexagon_lines_bg_02.png'
import '../assets/images/personas/claudio.jpg'
import '../assets/images/personas/leonardo.jpg'
import '../assets/images/personas/beatrice.jpg'
import '../assets/images/personas/claudio_schema.png'
import '../assets/images/personas/beatrice_schema.png'
import '../assets/images/personas/leonardo_schema.png'
import '../assets/images/personas/eva_schema.png'
import '../assets/images/personas/eva.jpg'

import '../assets/images/logos/bulloni_digitali_favicon.png'
import '../assets/images/logos/bulloni_digitali_logo.png'
import '../assets/images/logos/leanbit_logo3d.png'
import '../assets/images/logos/velex_logo.png'
import '../assets/images/logos/unox_logo.png'
import '../assets/images/logos/binarysystem_logo.png'
import '../assets/images/logos/binarysystem_black_logo.png'
import '../assets/images/logos/sanmarco_logo.png'
import '../assets/images/logos/oracle_cloud_logo.png'
import '../assets/images/logos/aws_logo.png'
import '../assets/images/logos/gcp_logo.png'
import '../assets/images/logos/AWS-select-consulting-partner.jpg'
import '../assets/images/logos/AWS-select-consulting-partner-amazon-connect.jpg'
import '../assets/images/logos/AWS-select-consulting-partner-amazon-connect_no_shadow.jpg'
import '../assets/images/logos/kitchen_c3_logo_symbol.svg'
import '../assets/images/logos/kitchen_c3_logo.svg'
import '../assets/images/logos/salesforce_logo.png'
import '../assets/images/logos/offcar_logo.png'
import '../assets/images/logos/wildix_logo.png'
import '../assets/images/logos/disenia_logo.jpg'
import '../assets/images/logos/leanbit_logo_square.jpg'
import '../assets/images/logos/nosco_modules_overview.jpg'

requireAll(require.context('../assets/images/authors/', true, /\.jpg$/));
requireAll(require.context('../assets/images/blog/', true, /\.jpg$|\.png$|\.jpeg$/));
requireAll(require.context('../assets/images/bulloni_digitali/', true, /\.jpg$|\.png$|\.jpeg$/));

import '../assets/images/smart_working/1-bg.jpg'
import '../assets/images/smart_working/1-aws.png'
import '../assets/images/smart_working/badge.png'
import '../assets/images/smart_working/footer.png'
import '../assets/images/smart_working/2-1.png'
import '../assets/images/smart_working/2-2.png'
import '../assets/images/smart_working/3-1.png'
import '../assets/images/smart_working/3-2.png'
import '../assets/images/smart_working/3-3.png'
import '../assets/images/smart_working/3-4.png'
import '../assets/images/smart_working/4-bg.jpg'
import '../assets/images/smart_working/4-1.png'
import '../assets/images/smart_working/4-2.png'
import '../assets/images/smart_working/5-1.png'
import '../assets/images/smart_working/5-2.png'
import '../assets/images/smart_working/7-1.png'
import '../assets/images/smart_working/7-1-s.jpg'
import '../assets/images/smart_working/7-2.png'
import '../assets/images/smart_working/7-2-s.jpg'
import '../assets/images/smart_working/7-3.png'
import '../assets/images/smart_working/7-3-s.jpg'
import '../assets/images/smart_working/7-4.png'
import '../assets/images/smart_working/7-4-s.jpg'
import '../assets/images/smart_working/personas-2.jpg'
import '../assets/images/smart_working/personas-1.jpg'
import '../assets/images/smart_working/personas-1-bg.jpg'
import '../assets/images/smart_working/personas-2-bg.jpg'

import '../assets/images/events/mkithp_san_marco.png'
import '../assets/images/events/san_marco_group.svg'
import '../assets/images/events/sanmarco_logo.png'
import '../assets/images/events/20210610_offcar/offcar_logo.png'
import '../assets/images/events/20210610_offcar/mkithp_backstage.jpg'
import '../assets/images/events/20210610_offcar/mkithp_sharing_hero_image_offcar.jpg'
import '../assets/images/events/20210610_offcar/industry_4point0.jpg'
import '../assets/images/events/20210922/evento_settembre_2021.jpg'
import '../assets/images/events/20210922/event_image.png'
import '../assets/images/events/20210922/event_image_share.png'

import '../assets/images/events/mkthap_sm_full.jpeg'
import '../assets/images/events/mkithp_interview.jpg'
import '../assets/images/events/agile_flow.png'
import '../assets/images/events/customer_engage.png'
import '../assets/images/events/productivity.png'
import '../assets/images/events/trouble.png'
import '../assets/images/events/matteo-interview.png'
import '../assets/images/events/matteo-interview-small.png'
import '../assets/images/events/laura-interview.png'
import '../assets/images/events/laura-interview-small.png'

import '../assets/images/wallpapers/working_man.jpg'
import '../assets/images/wallpapers/hexagon.png'
import '../assets/images/wallpapers/unox_wallpaper.jpg'
import '../assets/images/wallpapers/binarysystem_wallpaper.png'
import '../assets/images/wallpapers/san_marco_wallpaper.jpeg'
import '../assets/images/wallpapers/san_marco_wallpaper_full.png'
import '../assets/images/wallpapers/san_marco_wallpaper_extended.jpg'
import '../assets/images/wallpapers/amazon_connect_wallpaper.jpg'
import '../assets/images/wallpapers/unox_headquarter.jpeg'
import '../assets/images/wallpapers/offcar_wallpaper.jpg'
import '../assets/images/wallpapers/disenia_wallpaper.jpg'
import '../assets/images/wallpapers/disenia_hq.jpg'
import '../assets/images/wallpapers/superfry.jpg'

import '../assets/images/nosco_landing/efficiente.svg'
import '../assets/images/nosco_landing/intuitivo.svg'
import '../assets/images/nosco_landing/funzionale.svg'
import '../assets/images/nosco_landing/interattivo.svg'

import '../assets/images/nosco_overview/hero_image.png'

import '../assets/images/icons/reactive.png'

import '../assets/images/certificates/apave_9001_27001_small.jpg'

import '../vendor/front_theme/front'

import { startHomeTabs, mobileMenuCloser } from '../modules/home_utility'
import { smoothScroll } from '../modules/smooth_scroll'
import TrumbowygEditor from '../modules/trumbowyg_editor'
import AsyncFontLoader from '../modules/async_font_loader'

Rails.start()
Turbolinks.start()

function importAll(r) {
  return r.keys().map(r)
}

importAll(require.context('../assets/images/nosco', true, /\.(png|jpe?g|svg)$/))
importAll(require.context('../assets/images/landings', true, /\.(png|jpe?g|svg)$/))
importAll(require.context('../assets/images/solutions', true, /\.(png|jpe?g|svg)$/))
importAll(require.context('../assets/images/aws_connect', true, /\.(png|jpe?g|svg)$/))
importAll(require.context('../assets/images/leanbit_home', true, /\.(png|jpe?g|svg)$/))
importAll(require.context('../assets/images/leanbit_company', true, /\.(png|jpe?g|svg)$/))

function initialize() {
  // initialization of slick carousel
  $('.js-slick-carousel').each(function () {
    $.HSCore.components.HSSlickCarousel.init($(this)[0])
  })

  $('.expand-persona').click(function() {
    $('.block-personas').slideUp(200)
    $('.block-'+$(this).data('persona')).slideDown(200)
  })

  $('.close-persona').click(function() {
    $('.block-personas').slideDown(200)
    $(this).closest('.block-persona').slideUp(200)
  })

  mobileMenuCloser();
  smoothScroll();
  startHomeTabs();
  new TrumbowygEditor('.trumbowyg-nosco')
  new AsyncFontLoader().init()
}


document.addEventListener('turbolinks:load', () => {
  initialize();
})
